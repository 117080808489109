import * as React from "react"
import { Box, Center, Heading, Link, Text, VStack } from "@chakra-ui/react"
import Head from "next/head"
import NextLink from "next/link"

import { useMe } from "lib/hooks/useMe"


export default function Home() {
  const { me } = useMe()

  return (
    <Box>
      <Head>
        <title>Zestor</title>
      </Head>


        <Center flexDir="column">
          <VStack>
            <Heading as="h1" textAlign="center">
              Welcome to the Boilerplate
            </Heading>
            {me && (
              <Heading as="h2" fontSize="2xl">
                Hello, {me.firstName}!
              </Heading>
            )}
            <Text>
              Built by{" "}
              <NextLink href="https://www.noquarter.co" passHref>
                <Link _hover={{ textDecor: "underline" }} target="_blank" color="purple.500">
                  No Quarter
                </Link>
              </NextLink>
            </Text>
          </VStack>
        </Center>

    </Box>
  )
}
